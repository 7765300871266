import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/NavBar';

const Essays = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width: '100%' }}>
                <NavBar />
                <div>
                    <h1 style={{ margin: 0, color: "rgba(0, 0, 0, 0.1)" }}>David Dalmaso</h1>
                    <p><Link to="/failure">On failure</Link></p>
                </div>
            </div>
        </div>
    );
}

export default Essays;
