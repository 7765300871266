import React from 'react';
import { useNavigate } from 'react-router-dom';

const NavBar = () => {

    const navigate = useNavigate();

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '80px', marginRight: '20px' }}>
            <button style={{ cursor: "pointer" }} onClick={() => navigate('/')}>Home</button>
            <button style={{ cursor: "pointer" }} onClick={() => navigate('/essays')}>Essays</button>
            <button style={{ cursor: "pointer" }} onClick={() => navigate('/books')}>Books</button>
            <button style={{ cursor: "pointer" }} onClick={() => navigate('/articles')}>Articles</button>
        </div>
    )
}

export default NavBar;
