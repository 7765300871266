import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Essays from './pages/Essays';
import Books from './pages/Books';
import Articles from './pages/Articles';
import Failure from './pages/essays/Failure';

const App = () => {
    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/essays" element={<Essays />} />
                <Route path="/books" element={<Books />} />
                <Route path="/articles" element={<Articles />} />
                <Route path="/failure" element={<Failure />} />
            </Routes>
        </Router>
    );
}

export default App;
