// Failure.js
import React from 'react';
import NavBar from '../../components/NavBar';

const FAILURE_ESSAY = `
    <br></br>There are two types of failures — failures of action and failures of inaction. Everyone will inevitably experience both of these at some point in their life, but it’s up to you to decide which one you want take on. We’re more familiar with failures of action. It’s asking out your crush and getting rejected. It’s starting a business that eventually fails. It’s setting a goal and falling short. Failure of inaction is more pernicious. It’s never knowing what your crush would’ve said since you didn’t act. It’s sitting around thinking about great ideas but never acting on them. It’s living a life of what-ifs.<br/><br/>

    We make decisions every day, and while some decisions seem more complicated than others, they’re all really quite simple. You do something or you don’t.<br/><br/>

    At its best, failure of action is romanticized and celebrated. People may commend you for the bravery and ambition, extolling the lessons learned and how you are stronger for it. <strong>All of this is true</strong>, but it feels hollow in the moment. In reality, experiencing failure is profoundly disheartening. At its worst, failure of action is the result of putting in hard work, expending emotional and physical energy, putting in what feels like wasted time, and coming up short on what you dreamt of doing. It can bring out questions of self-worth and self-confidence. Worst of all, if this was something you always wanted to do, where does that leave you? Life feels empty when you lose that purpose.<br/><br/>

    Failure of inaction, on the other hand, is a life of dreaming. There are a million things to do in this world, and imagining yourself doing any one of them is inspiring and exciting. The two hard truths are this: sometimes wanting something feels better than having it, and in reality, we can’t do it all. It’s not that you can’t achieve any single goal, but you can’t actually go and live the million hypothetical lives you’ve imagined for yourself. This is a hard truth that people need to understand. There are likely some future versions of yourself that you always seem to come back to. Photography does sound interesting, but music is what you constantly come back to. Owning a local bar would be cool, but you have bigger dreams of starting a company that makes it way to the public markets. Failure is not coming short of everything you’ve ever dreamt of. Failure is never taking that risk to pursue the one thing you really believe in.<br/><br/>

    Failure goes hand in hand with risk. We manage risk everyday whether or not we realize it. We avoid certain streets because of commotion taking place. We keep thoughts to ourselves out of fear of what others might think. We don’t eat certain foods that look or smell funny. These are all rooted in fears - safety, embarrassment, health. Risk assessment happens every day at the micro scale but often gets ignored at the macro. Are our habits trending us in the right direction? Is our current job setting us up for career success? You take the joy out of life by constantly evaluating your decisions, but it’s important to think that what you’re doing had some thought and managed risk behind it.<br/><br/>

    There’s not a one size fits all solution to this. Some people are predisposed to risk more than others, some may have dependents that you need to take account for, some prioritize security and comfort more, all of which are fine solutions. But for the people that can’t stop thinking about starting that business, picking up that hobby, reaching out to your crush, you’re failing each day you put it off. When it comes down to it, you can evaluate that risk. “Worst thing they can say is no” or “Might be a waste of time” are colloquial examples of that risk assessment. But for bigger riskier, decisions, you can break down that risk. Interested in starting a business? How much time and money are you willing to invest? What’s the opportunity cost towards your current job? Even in the failure case, are you positioned well after for a backup plan?<br/><br/>

    <s>Passion is overrated, hard work is not.</s> Dreams are overrated, passion is not.<br/><br/>

    I’ve experienced my fair share of failures of action. A business, a relationship, an investment. When reflecting on if these efforts were worthwhile, it’s hard to say yes considering the pain of that failure. However, I know how badly they hurt me, but I’d have never known how badly it’d hurt to have never done anything at all.<br/><br/>

    Success is never guaranteed, but the only way to make sure you never reach it is through not trying.
`;

const Failure = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: 300 }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width: '100%' }}>
                <NavBar />
                <div>
                    <h1 style={{ margin: 0, color: "rgba(0, 0, 0, 0.1)" }}>Failure</h1>
                    <div style={{ width: 500 }}>
                       <div dangerouslySetInnerHTML={{ __html: FAILURE_ESSAY }} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Failure;
